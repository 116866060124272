@media print {
  body * {
    visibility: hidden;
  }
  #printArea * {
    visibility: visible;
  }
  #printArea {
    position: absolute;
    left: 0;
    top: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
